import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Box, Paper, TextField, IconButton, Select, MenuItem, InputLabel, FormControl, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles
import config from '../../config';
import './BlogPage.css';

const BlogPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [newBlog, setNewBlog] = useState({ title: '', description: '', body: '', image: null, blog_category_id: '' });
  const [content, setContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [expandedBlog, setExpandedBlog] = useState(null);
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
  const [selectedBlogBody, setSelectedBlogBody] = useState('');

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = () => {
    axios.get(`${config.baseUrl}/blog-posts`)
      .then(response => {
        setBlogs(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the blogs!", error);
        setError('Failed to fetch blogs. Please try again later.');
      });
  };

  const fetchCategories = () => {
    axios.get(`${config.baseUrl}/getcategorieswithcount`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the categories!", error);
        setError('Failed to fetch categories. Please try again later.');
      });
  };

  const handleAddBlog = () => {
    const formData = new FormData();
    formData.append('title', newBlog.title);
    formData.append('description', newBlog.description);
    formData.append('body', content); // Ensure content is added to formData
    formData.append('blog_category_id', newBlog.blog_category_id);
    if (newBlog.image) {
      formData.append('image', newBlog.image);
    }

    axios.post(`${config.baseUrl}/blog-posts`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      fetchBlogs();
      setNewBlog({ title: '', description: '', body: '', image: null, blog_category_id: '' });
      setContent('');
      setPreviewImage(null);
      setDialogOpen(false);
    })
    .catch(error => {
      console.error("There was an error adding the blog!", error);
      setError('Failed to add blog. Please try again later.');
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setNewBlog({ ...newBlog, image: file });
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleDeleteBlog = (id) => {
    axios.delete(`${config.baseUrl}/blog-posts/${id}`)
      .then(response => {
        fetchBlogs();
      })
      .catch(error => {
        console.error("There was an error deleting the blog!", error);
        setError('Failed to delete blog. Please try again later.');
      });
  };

  const handleExpandBlog = (id) => {
    setExpandedBlog(expandedBlog === id ? null : id);
  };

  const renderBlogDescription = (description) => {
    return { __html: description.length > 100 ? description.substring(0, 100) + '...' : description };
  };

  const handleOpenBodyDialog = (body) => {
    setSelectedBlogBody(body);
    setDescriptionDialogOpen(true);
  };

  const handleEditBlog = (blog) => {
    setNewBlog(blog);
    setContent(blog.body);
    setPreviewImage(`${config.imagebaseUrl}/${blog.image}`);
    setEditDialogOpen(true);
  };

  const handleUpdateBlog = () => {
    const formData = new FormData();
    formData.append('title', newBlog.title);
    formData.append('description', newBlog.description);
    formData.append('body', content); // Ensure content is added to formData
    formData.append('blog_category_id', newBlog.blog_category_id);
    if (newBlog.image && newBlog.image instanceof File) {
      formData.append('image', newBlog.image);
    }

    axios.post(`${config.baseUrl}/update-blog/${newBlog.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      fetchBlogs();
      setNewBlog({ title: '', description: '', body: '', image: null, blog_category_id: '' });
      setContent('');
      setPreviewImage(null);
      setEditDialogOpen(false);
    })
    .catch(error => {
      console.error("There was an error updating the blog!", error);
      setError('Failed to update blog. Please try again later.');
    });
  };

  return (
    <Box className="blog-page">
      <Typography variant="h4" component="h1" textAlign="center" marginBottom="20px">
        Blog Management
      </Typography>
      {error && <Typography variant="body1" color="error" textAlign="center">{error}</Typography>}
      <Box textAlign="center" marginBottom="20px">
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} className="add-blog-button">
          Add New Blog
        </Button>
      </Box>
      <Grid container spacing={2}>
        {blogs.map(blog => (
          <Grid item xs={12} md={6} lg={4} key={blog.id}>
            <Paper elevation={3} className="blog-item">
              <Box padding={2}>
                <Typography variant="h6" className="blog-title">{blog.title}</Typography>
                <Typography variant="body2" dangerouslySetInnerHTML={renderBlogDescription(blog.description)} />
                <Button onClick={() => handleOpenBodyDialog(blog.body)} className="see-more-button">
                  See More
                </Button>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={2}>
                  <IconButton className="edit-button" onClick={() => handleEditBlog(blog)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton className="delete-button" onClick={() => handleDeleteBlog(blog.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              <img src={`${config.imagebaseUrl}/${blog.image}`} alt={blog.title} className="blog-image" />
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Add New Blog</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            variant="outlined"
            value={newBlog.title}
            onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Description"
            variant="outlined"
            value={newBlog.description}
            onChange={(e) => setNewBlog({ ...newBlog, description: e.target.value })}
            margin="normal"
            fullWidth
            multiline
            rows={3}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={newBlog.blog_category_id}
              onChange={(e) => setNewBlog({ ...newBlog, blog_category_id: e.target.value })}
            >
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image', 'video'
            ]}
            className="quill-editor"
          />
          <div className="image-upload-section">
            {previewImage ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : (
              <div className="upload-placeholder">No image selected</div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              id="upload-blog-image"
            />
            <label htmlFor="upload-blog-image" className="upload-label">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
              <p>Upload Blog Image</p>
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddBlog} color="primary">
            Add Blog
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Edit Blog</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            variant="outlined"
            value={newBlog.title}
            onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
            margin="normal"
            fullWidth
          />
          <TextField
            label="Description"
            variant="outlined"
            value={newBlog.description}
            onChange={(e) => setNewBlog({ ...newBlog, description: e.target.value })}
            margin="normal"
            fullWidth
            multiline
            rows={3}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Category</InputLabel>
            <Select
              value={newBlog.blog_category_id}
              onChange={(e) => setNewBlog({ ...newBlog, blog_category_id: e.target.value })}
            >
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ReactQuill
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image', 'video'
            ]}
            className="quill-editor"
          />
          <div className="image-upload-section">
            {previewImage ? (
              <img src={previewImage} alt="Preview" className="preview-image" />
            ) : (
              <div className="upload-placeholder">No image selected</div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              id="edit-upload-blog-image"
            />
            <label htmlFor="edit-upload-blog-image" className="upload-label">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
              <p>Upload Blog Image</p>
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateBlog} color="primary">
            Update Blog
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={descriptionDialogOpen} onClose={() => setDescriptionDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Blog Body</DialogTitle>
        <DialogContent>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: selectedBlogBody }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDescriptionDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BlogPage;
