import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './BodyTypes.css';

const BodyTypes = () => {
  const [bodyTypes, setBodyTypes] = useState([]);
  const [newBodyTypeName, setNewBodyTypeName] = useState('');
  const [bodyTypeDialogOpen, setBodyTypeDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBodyTypes();
  }, []);

  const fetchBodyTypes = () => {
    axios.get(`${config.baseUrl}/body-types`)
      .then(response => {
        setBodyTypes(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the body types!", error);
        setError('Failed to fetch body types. Please try again later.');
      });
  };

  const handleAddBodyType = () => {
    const formData = new FormData();
    formData.append('type', newBodyTypeName);

    axios.post(`${config.baseUrl}/body-types`, formData)
      .then(response => {
        fetchBodyTypes();
        setNewBodyTypeName('');
        setBodyTypeDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error adding the body type!", error);
        setError('Failed to add body type. Please try again later.');
      });
  };

  const handleDeleteBodyType = (bodyTypeId) => {
    axios.delete(`${config.baseUrl}/body-types/${bodyTypeId}`)
      .then(response => {
        fetchBodyTypes();
      })
      .catch(error => {
        console.error("There was an error deleting the body type!", error);
        setError('Failed to delete body type. Please try again later.');
      });
  };

  return (
    <div className="body-types">
      <h1>Body Types</h1>
      <div className="add-body-type">
        <Button className="add-body-type-button" onClick={() => setBodyTypeDialogOpen(true)}>
          Add Body Type
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="body-type-list">
        {bodyTypes.map(bodyType => (
          <div key={bodyType.id} className="body-type-item">
            <p>{bodyType.type}</p>
            <IconButton onClick={() => handleDeleteBodyType(bodyType.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>

      <Dialog open={bodyTypeDialogOpen} onClose={() => setBodyTypeDialogOpen(false)}>
        <DialogTitle>Add New Body Type</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Body Type"
            type="text"
            fullWidth
            value={newBodyTypeName}
            onChange={(e) => setNewBodyTypeName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBodyTypeDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddBodyType}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BodyTypes;
