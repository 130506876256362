import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, IconButton, CircularProgress, FormHelperText } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import config from '../../config';
import './Manufacturers.css';

const Manufacturers = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [error, setError] = useState(null);
  const [newManufacturerName, setNewManufacturerName] = useState('');
  const [newManufacturerImage, setNewManufacturerImage] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false); // State for loading

  useEffect(() => {
    fetchManufacturers();
  }, []);

  const fetchManufacturers = () => {
    axios.get(`${config.baseUrl}/manufacturers`)
      .then(response => {
        setManufacturers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the manufacturers!", error);
        setError('Failed to fetch manufacturers. Please try again later.');
      });
  };

  const handleAddManufacturer = () => {
    const errors = {};
    if (!newManufacturerName) {
      errors.name = 'Manufacturer name is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', newManufacturerName);
      if (newManufacturerImage) {
        formData.append('image', newManufacturerImage);
      }

      axios.post(`${config.baseUrl}/manufacturers`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          fetchManufacturers();
          setNewManufacturerName('');
          setNewManufacturerImage(null);
          setDialogOpen(false);
          setLoading(false);
        })
        .catch(error => {
          console.error("There was an error adding the manufacturer!", error);
          setError('Failed to add manufacturer. Please try again later.');
          setLoading(false);
        });
    }
  };

  const handleDeleteManufacturer = (id) => {
    axios.delete(`${config.baseUrl}/manufacturers/${id}`)
      .then(response => {
        fetchManufacturers();
      })
      .catch(error => {
        console.error("There was an error deleting the manufacturer!", error);
        setError('Failed to delete manufacturer. Please try again later.');
      });
  };

  const handleImageUpload = (e) => {
    setNewManufacturerImage(e.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setNewManufacturerImage(e.dataTransfer.files[0]);
  };

  const filteredManufacturers = manufacturers.filter(manufacturer =>
    manufacturer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="manufacturers">
      <h1>Manufacturers</h1>
      <div className="add-manufacturer">
        <TextField
          className="search-field"
          label="Search by name"
          variant="outlined"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} className="add-manufacturer-button">
          Add Manufacturer
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="manufacturer-list">
        {filteredManufacturers.map(manufacturer => (
          <div key={manufacturer.id} className="manufacturer-item">
            <img src={`${config.imagebaseUrl}/${manufacturer.image}`} alt={manufacturer.name} />
            <div className="manufacturer-info">
              <h2>{manufacturer.name}</h2>
              <Button onClick={() => handleDeleteManufacturer(manufacturer.id)}>Delete</Button>
            </div>
          </div>
        ))}
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Add New Manufacturer</DialogTitle>
        <DialogContent>
          <TextField
            label="Manufacturer Name"
            fullWidth
            variant="outlined"
            value={newManufacturerName}
            onChange={(e) => setNewManufacturerName(e.target.value)}
            margin="normal"
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />
          <div
            className="drag-drop-box"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            {newManufacturerImage ? (
              <img src={URL.createObjectURL(newManufacturerImage)} alt="Preview" className="preview-image" />
            ) : (
              <div className="upload-icon">
                <CloudUploadIcon />
                <p>Drag & Drop image here or click to upload</p>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              id="upload-button"
            />
            <label htmlFor="upload-button" className="upload-label">
              <IconButton component="span">
                <CloudUploadIcon />
              </IconButton>
            </label>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddManufacturer} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <div className="loading-overlay">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default Manufacturers;
