import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IconButton, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './SellYourCars.css';
import config from '../../config';

const SellYourCars = () => {
  const [carSales, setCarSales] = useState([]);
  const [error, setError] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editorContent, setEditorContent] = useState('');

  useEffect(() => {
    fetchCarSales();
    fetchIntroduction();
  }, []);

  const fetchCarSales = () => {
    axios.get(`${config.baseUrl}/car-sales`)
      .then(response => {
        setCarSales(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the car sales!", error);
        setError('Failed to fetch car sales. Please try again later.');
      });
  };

  const fetchIntroduction = () => {
    axios.get(`${config.baseUrl}/html-content`)
      .then(response => {
        if (response.data) {
          setEditorContent(response.data.html_content);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the introduction content!", error);
        setError('Failed to fetch introduction content. Please try again later.');
      });
  };

  const handleDeleteCarSale = (id) => {
    axios.delete(`${config.baseUrl}/car-sales/${id}`)
      .then(response => {
        fetchCarSales();
      })
      .catch(error => {
        console.error("There was an error deleting the car sale!", error);
        setError('Failed to delete car sale. Please try again later.');
      });
  };

  const handleModifyIntroduction = () => {
    setDialogOpen(true);
  };

  const handleSaveIntroduction = () => {
    axios.post(`${config.baseUrl}/html-content`, { html_content: editorContent })
      .then(response => {
        setDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error saving the introduction content!", error);
        setError('Failed to save introduction content. Please try again later.');
      });
  };

  return (
    <div className="sell-your-cars">
      <h1>Sell Your Cars</h1>
      <Button onClick={handleModifyIntroduction} style={{ marginBottom: '20px', backgroundColor: '#1976d2', color: '#fff', padding: '10px 20px', borderRadius: '5px', textTransform: 'none' }}>
        Modify Introduction
      </Button>

      {error && <div className="error-message">{error}</div>}
      <div className="car-sales-list">
        {carSales.map(car => (
          <div key={car.id} className="car-sale-item">
            <div className="car-sale-details">
              <h2>{car.manufacturer} {car.model} ({car.model_year})</h2>
              <p><strong>Chassis No:</strong> {car.chassis_no}</p>
              <p><strong>Kilometers:</strong> {car.kilometers}</p>
              <p><strong>Engine Size:</strong> {car.engine_size}</p>
              <p><strong>Options:</strong> {car.vehicle_options}</p>
              <p><strong>Price:</strong> ${car.expected_price}</p>
              <p><strong>Description:</strong> {car.description}</p>
              <p><strong>Owner:</strong> {car.full_name}</p>
              <p><strong>Contact:</strong> {car.mobile_number} / {car.email_address}</p>
            </div>
            <IconButton onClick={() => handleDeleteCarSale(car.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Modify Introduction</DialogTitle>
        <DialogContent>
          <ReactQuill value={editorContent} onChange={setEditorContent} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveIntroduction} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SellYourCars;
