import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Collapse } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './CarModels.css';

const CarModels = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState({});
  const [selectedManufacturer, setSelectedManufacturer] = useState('');
  const [newModelName, setNewModelName] = useState('');
  const [modelsDialogOpen, setModelsDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [openManufacturer, setOpenManufacturer] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchManufacturers();
  }, []);

  const fetchManufacturers = () => {
    axios.get(`${config.baseUrl}/manufacturers`)
      .then(response => {
        setManufacturers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the manufacturers!", error);
        setError('Failed to fetch manufacturers. Please try again later.');
      });
  };

  const fetchModelsByManufacturer = (manufacturerId) => {
    axios.get(`${config.baseUrl}/car-models/manufacturer/${manufacturerId}`)
      .then(response => {
        setModels(prevModels => ({
          ...prevModels,
          [manufacturerId]: response.data
        }));
        setOpenManufacturer(openManufacturer === manufacturerId ? null : manufacturerId);
      })
      .catch(error => {
        console.error("There was an error fetching the models!", error);
        setError('Failed to fetch models. Please try again later.');
      });
  };

  const handleAddModel = () => {
    const formData = new FormData();
    formData.append('name', newModelName);
    formData.append('manufacturer_id', selectedManufacturer);

    axios.post(`${config.baseUrl}/car-models`, formData)
      .then(response => {
        fetchModelsByManufacturer(selectedManufacturer);
        setNewModelName('');
        setModelsDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error adding the model!", error);
        setError('Failed to add model. Please try again later.');
      });
  };

  const handleDeleteModel = (modelId, manufacturerId) => {
    axios.delete(`${config.baseUrl}/car-models/${modelId}`)
      .then(response => {
        fetchModelsByManufacturer(manufacturerId);
      })
      .catch(error => {
        console.error("There was an error deleting the model!", error);
        setError('Failed to delete model. Please try again later.');
      });
  };

  const filteredManufacturers = manufacturers.filter(manufacturer =>
    manufacturer.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="car-models">
      <h1>Car Models</h1>
      <div className="add-model">
        <TextField
          className="search-field"
          label="Search by name"
          variant="outlined"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          margin="normal"
        />
        <Button className="add-model-button" onClick={() => setModelsDialogOpen(true)}>
          Add Model
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="manufacturer-list">
        {filteredManufacturers.map(manufacturer => (
          <div key={manufacturer.id} className="manufacturer-item">
            <img src={`${config.imagebaseUrl}/${manufacturer.image}`} alt={manufacturer.name} />
            <h2 onClick={() => fetchModelsByManufacturer(manufacturer.id)}>{manufacturer.name}</h2>
            <Collapse in={openManufacturer === manufacturer.id}>
              {models[manufacturer.id]?.map(model => (
                <div key={model.id} className="model-item">
                  <p>{model.name}</p>
                  <IconButton onClick={() => handleDeleteModel(model.id, manufacturer.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </Collapse>
          </div>
        ))}
      </div>

      <Dialog open={modelsDialogOpen} onClose={() => setModelsDialogOpen(false)}>
        <DialogTitle>Add New Model</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Manufacturer</InputLabel>
            <Select
              value={selectedManufacturer}
              onChange={(e) => setSelectedManufacturer(e.target.value)}
            >
              {manufacturers.map(manufacturer => (
                <MenuItem key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            label="Model Name"
            type="text"
            fullWidth
            value={newModelName}
            onChange={(e) => setNewModelName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModelsDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddModel}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CarModels;
