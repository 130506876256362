import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import DeleteIcon from '@mui/icons-material/Delete';
import config from '../../config';
import './CarItem.css';
import { useNavigate } from 'react-router-dom';

const CarItem = ({ car, onDelete, onRemoveImage }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleShowDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleEdit = () => {
    navigate(`/edit-car/${car.id}`);
  };

  return (
    <div className="car-item">
      <div className="additional-images">
        {car.images && car.images.map((img, index) => (
          <img key={index} src={`${config.imagebaseUrl}/${img.image_path}`} alt={`Additional ${index}`} className="additional-image" />
        ))}
      </div>
      <img src={`${config.imagebaseUrl}/${car.image}`} alt={car.title} className="car-image" />
      <div className="car-details">
        <h3>{car.title}</h3>
        <p><strong>Year:</strong> {car.year}</p>
        <p><strong>Price:</strong> ${car.price}</p>
        <p><strong>Gear Type:</strong> {car.gear_type}</p>
        <p><strong>Fuel Type:</strong> {car.fuel_type}</p>
        <p><strong>Warranty:</strong> {car.warranty}</p>
        <div className="car-item-buttons">
          <Button variant="contained" onClick={handleShowDialog}>
            Show
          </Button>
          <Button variant="contained" onClick={handleEdit}>
            Edit
          </Button>
          <Button variant="contained" color="secondary" onClick={() => onDelete(car.id)}>
            Delete
          </Button>
        </div>
      </div>

      <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>{car.title}</DialogTitle>
        <DialogContent>
          <Carousel showThumbs={false} showStatus={false} infiniteLoop>
            {car.images && car.images.map((img, index) => (
              <div key={index}>
                <img src={`${config.imagebaseUrl}/${img.image_path}`} alt={`Additional ${index}`} className="carousel-image" />
                <IconButton
                  color="secondary"
                  onClick={() => onRemoveImage(img.id)}
                  style={{ position: 'absolute', top: '10px', right: '10px' }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
          </Carousel>
          <div className="car-details-dialog">
            <p><strong>Year:</strong> {car.year}</p>
            <p><strong>Price:</strong> ${car.price}</p>
            <p><strong>Gear Type:</strong> {car.gear_type}</p>
            <p><strong>Fuel Type:</strong> {car.fuel_type}</p>
            <p><strong>Warranty:</strong> {car.warranty}</p>
            <p><strong>Mileage:</strong> {car.mileage}</p>
            <p><strong>Phone:</strong> {car.phone}</p>
            <p><strong>Engine Size:</strong> {car.engine_size}</p>
            <p><strong>Service Contact:</strong> {car.service_contact}</p>
            <p><strong>Description:</strong> {car.description}</p>
            <p><a href={car.instagram_post} target="_blank" rel="noopener noreferrer">Instagram Post</a></p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CarItem.propTypes = {
  car: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
};

export default CarItem;
