import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import config from '../../config';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useDropzone } from 'react-dropzone'; // Importing useDropzone for drag-and-drop functionality
import './Partners.css';

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [newPartnerImage, setNewPartnerImage] = useState(null); // State for partner image
  const [partnerDialogOpen, setPartnerDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPartners();
  }, []);

  const fetchPartners = () => {
    axios.get(`${config.baseUrl}/partners`)
      .then(response => {
        setPartners(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the partners!", error);
        setError('Failed to fetch partners. Please try again later.');
      });
  };

  const handleAddPartner = () => {
    const formData = new FormData();
    formData.append('image', newPartnerImage); // Add image to form data

    axios.post(`${config.baseUrl}/partners`, formData)
      .then(response => {
        fetchPartners();
        setNewPartnerImage(null); // Reset image input
        setPartnerDialogOpen(false);
      })
      .catch(error => {
        console.error("There was an error adding the partner!", error);
        setError('Failed to add partner. Please try again later.');
      });
  };

  const handleDeletePartner = (id) => {
    axios.delete(`${config.baseUrl}/partners/${id}`)
      .then(response => {
        fetchPartners();
      })
      .catch(error => {
        console.error("There was an error deleting the partner!", error);
        setError('Failed to delete partner. Please try again later.');
      });
  };

  // Dropzone for handling drag-and-drop image upload
  const onDrop = useCallback((acceptedFiles) => {
    setNewPartnerImage(acceptedFiles[0]); // Set the first accepted file as the new image
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false, // Only allow one image at a time
  });

  return (
    <div className="partners">
      <h1>Partners</h1>
      <div className="add-partner">
        <Button className="add-partner-button" onClick={() => setPartnerDialogOpen(true)}>
          <AddIcon /> Add Partner
        </Button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="partner-list">
        {partners.map(partner => (
          <div key={partner.id} className="partner-item">
            {/* Display image */}
            <img src={`${config.imagebaseUrl}/${partner.name}`} alt="Partner" className="partner-image" />
            <IconButton onClick={() => handleDeletePartner(partner.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>

      <Dialog open={partnerDialogOpen} onClose={() => setPartnerDialogOpen(false)}>
        <DialogTitle>Add New Partner</DialogTitle>
        <DialogContent>
          {/* Drag and drop zone for uploading image */}
          <div
            {...getRootProps()}
            style={{
              border: '2px dashed #aaa',
              padding: '20px',
              textAlign: 'center',
              cursor: 'pointer',
              marginBottom: '20px',
              backgroundColor: isDragActive ? '#e3f2fd' : 'white',
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the image here...</p>
            ) : (
              <p>Drag and drop an image here, or click to select one</p>
            )}
            {newPartnerImage && (
              <p style={{ marginTop: '10px' }}>{newPartnerImage.name}</p> // Show the name of the selected image
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPartnerDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddPartner}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Partners;
