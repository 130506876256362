import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import CarItem from './CarItem';
import {
  Button, Typography, Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions,
  Select, MenuItem, InputLabel, FormControl, FormHelperText, IconButton, CircularProgress
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteIcon from '@mui/icons-material/Delete';


import './CarListing.css'; // Ensure you have the CSS file imported

const CarListing = () => {
  const [carListings, setCarListings] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [owners, setOwners] = useState([]);
  const [currentCar, setCurrentCar] = useState(null);
  const [newCar, setNewCar] = useState({
    title: '',
    year: '',
    gear_type: '',
    fuel_type: '',
    service_contact: '',
    mileage: '',
    price: '',
    phone: '',
    car_model_id: '',
    body_type_id: '',
    owner_id: '',
    manufacturer_id: '',
    regional_spec: '',
    engine_size: '',
    description: '',
    instagram_post: '',
    warranty: '',
    price_per_month: '',
    image: null,
    images: [],
  });
  const [errors, setErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [loading, setLoading] = useState(false); // Added state for loading

  useEffect(() => {
    fetchCarListings();
    fetchManufacturers();
    fetchBodyTypes();
    fetchOwners();
  }, []);

  const fetchCarListings = () => {
    axios.get(`${config.baseUrl}/car-listings`)
      .then(response => {
        setCarListings(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the car listings!", error);
      });
  };

  const fetchManufacturers = () => {
    axios.get(`${config.baseUrl}/manufacturers`)
      .then(response => {
        setManufacturers(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the manufacturers!", error);
      });
  };

  const deleteCarListing = (carListing) => {
    axios.delete(`${config.baseUrl}/car-listings/${carListing}`)
      .then(response => {
        fetchCarListings();
      })
      .catch(error => {
        console.error("There was an error deleting the car listing!", error);
      });
  };

  const fetchCarModels = (manufacturerId) => {
    axios.get(`${config.baseUrl}/car-models/manufacturer/${manufacturerId}`)
      .then(response => {
        setCarModels(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the car models!", error);
      });
  };

  const fetchBodyTypes = () => {
    axios.get(`${config.baseUrl}/body-types`)
      .then(response => {
        setBodyTypes(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the body types!", error);
      });
  };

  const fetchOwners = () => {
    axios.get(`${config.baseUrl}/owners`)
      .then(response => {
        setOwners(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the owners!", error);
      });
  };

  const handleAddCar = () => {
    setNewCar({
      title: '',
      year: '',
      gear_type: '',
      fuel_type: '',
      service_contact: '',
      mileage: '',
      price: '',
      phone: '',
      car_model_id: '',
      body_type_id: '',
      owner_id: '',
      manufacturer_id: '',
      regional_spec: '',
      engine_size: '',
      description: '',
      instagram_post: '',
      warranty: '',
      price_per_month: '',
      image: null,
      images: [],
    });
    setPreviewImage(null);
    setPreviewImages([]);
    setDialogOpen(true);
    setCurrentCar(null);
  };

  const handleEditCar = (car) => {
    setNewCar({
      ...car,
      image: null,  // Reset the image field
      images: [],  // Reset additional images
    });
    setPreviewImage(`${config.imagebaseUrl}/${car.image}`);
    setPreviewImages(car.images.map(img => `${config.imagebaseUrl}/${img.image_path}`));
    setDialogOpen(true);
    setCurrentCar(car);
    fetchCarModels(car.manufacturer_id);  // Fetch models for the selected manufacturer
  };

  const handlePrimaryImageUpload = (e) => {
    const file = e.target.files[0];
    setNewCar({ ...newCar, image: file });
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleAdditionalImagesUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImages = [...newCar.images, ...files];
    setNewCar({ ...newCar, images: newImages });
    const imageUrls = newImages.map(file => URL.createObjectURL(file));
    setPreviewImages(imageUrls);
  };

  const handleRemoveAdditionalImage = (index) => {
    const newImages = [...newCar.images];
    newImages.splice(index, 1);
    setNewCar({ ...newCar, images: newImages });
    setPreviewImages(newImages.map(file => URL.createObjectURL(file)));
  };

  const handleSaveCar = () => {
    const newErrors = {};
    setLoading(true); // Set loading to true
    Object.keys(newCar).forEach(key => {
      if (!newCar[key] && key !== 'description' && key !== 'images') {
        newErrors[key] = 'This field is required';
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoading(false); // Set loading to false if there are validation errors
      return;
    }

    const formData = new FormData();
    Object.keys(newCar).forEach(key => {
      if (key === 'images') {
        newCar.images.forEach(image => formData.append('images[]', image));
      } else {
        formData.append(key, newCar[key]);
      }
    });

    const url = currentCar ? `${config.baseUrl}/car-listings/update/${currentCar.id}` : `${config.baseUrl}/car-listings`;
    console.log('API URL:', url);
    console.log('Payload:', Array.from(formData.entries()));
    axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        fetchCarListings();
        setNewCar({
          title: '',
          year: '',
          gear_type: '',
          fuel_type: '',
          service_contact: '',
          mileage: '',
          price: '',
          phone: '',
          car_model_id: '',
          body_type_id: '',
          owner_id: '',
          manufacturer_id: '',
          regional_spec: '',
          engine_size: '',
          description: '',
          instagram_post: '',
          warranty: '',
          price_per_month: '',
          image: null,
          images: [],
        });
        setPreviewImage(null);
        setPreviewImages([]);
        setDialogOpen(false);
        setErrors({});
        setLoading(false); // Set loading to false after successful save
      })
      .catch(error => {
        console.error("There was an error saving the car listing!", error.response?.data || error.message);
        setLoading(false); // Set loading to false if there's an error
      });
  };

  return (
    <div className="car-listing">
      <button className="add-car-button" onClick={handleAddCar}>
        Add Car
      </button>
      <div className="car-listing-grid">
        {carListings.map(car => (
          <CarItem key={car.id} car={car} onEdit={handleEditCar} onDelete={() => deleteCarListing(car.id)} />
        ))}
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{currentCar ? 'Edit Car' : 'Add New Car'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            variant="outlined"
            value={newCar.title}
            onChange={(e) => setNewCar({ ...newCar, title: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            label="Year"
            variant="outlined"
            value={newCar.year}
            onChange={(e) => setNewCar({ ...newCar, year: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.year}
            helperText={errors.year}
          />
          <FormControl fullWidth margin="normal" error={!!errors.gear_type}>
            <InputLabel>Gear Type</InputLabel>
            <Select
              value={newCar.gear_type}
              onChange={(e) => setNewCar({ ...newCar, gear_type: e.target.value })}
            >
              <MenuItem value="Automatic">Automatic</MenuItem>
              <MenuItem value="Manual">Manual</MenuItem>
            </Select>
            {errors.gear_type && <FormHelperText>{errors.gear_type}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.fuel_type}>
  <InputLabel>Fuel Type</InputLabel>
  <Select
    value={newCar.fuel_type}
    onChange={(e) => setNewCar({ ...newCar, fuel_type: e.target.value })}
  >
    <MenuItem value="Petrol">Petrol</MenuItem>
    <MenuItem value="Diesel">Diesel</MenuItem>
    <MenuItem value="Electric">Electric</MenuItem>
    <MenuItem value="Hybrid">Hybrid</MenuItem>
    <MenuItem value="CNG">CNG (Compressed Natural Gas)</MenuItem>
    <MenuItem value="LPG">LPG (Liquefied Petroleum Gas)</MenuItem>
    <MenuItem value="Hydrogen">Hydrogen</MenuItem>
  </Select>
  {errors.fuel_type && <FormHelperText>{errors.fuel_type}</FormHelperText>}
</FormControl>

          <TextField
            label="Service Contact"
            variant="outlined"
            value={newCar.service_contact}
            onChange={(e) => setNewCar({ ...newCar, service_contact: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.service_contact}
            helperText={errors.service_contact}
          />
          <TextField
            label="Mileage"
            variant="outlined"
            value={newCar.mileage}
            onChange={(e) => setNewCar({ ...newCar, mileage: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.mileage}
            helperText={errors.mileage}
          />
          <TextField
            label="Price"
            variant="outlined"
            value={newCar.price}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setNewCar({ ...newCar, price: value });
              }
            }}
            margin="normal"
            fullWidth
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            label="Phone"
            variant="outlined"
            value={newCar.phone}
            onChange={(e) => setNewCar({ ...newCar, phone: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <FormControl fullWidth margin="normal" error={!!errors.manufacturer_id}>
            <InputLabel>Manufacturer</InputLabel>
            <Select
              value={newCar.manufacturer_id}
              onChange={(e) => {
                setNewCar({ ...newCar, manufacturer_id: e.target.value });
                fetchCarModels(e.target.value);
              }}
            >
              {manufacturers.map(manufacturer => (
                <MenuItem key={manufacturer.id} value={manufacturer.id}>
                  {manufacturer.name}
                </MenuItem>
              ))}
            </Select>
            {errors.manufacturer_id && <FormHelperText>{errors.manufacturer_id}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.car_model_id}>
            <InputLabel>Car Model</InputLabel>
            <Select
              value={newCar.car_model_id}
              onChange={(e) => setNewCar({ ...newCar, car_model_id: e.target.value })}
            >
              {carModels.map(carModel => (
                <MenuItem key={carModel.id} value={carModel.id}>
                  {carModel.name}
                </MenuItem>
              ))}
            </Select>
            {errors.car_model_id && <FormHelperText>{errors.car_model_id}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.body_type_id}>
            <InputLabel>Body Type</InputLabel>
            <Select
              value={newCar.body_type_id}
              onChange={(e) => setNewCar({ ...newCar, body_type_id: e.target.value })}
            >
              {bodyTypes.map(bodyType => (
                <MenuItem key={bodyType.id} value={bodyType.id}>
                  {bodyType.type}
                </MenuItem>
              ))}
            </Select>
            {errors.body_type_id && <FormHelperText>{errors.body_type_id}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.owner_id}>
            <InputLabel>Owner</InputLabel>
            <Select
              value={newCar.owner_id}
              onChange={(e) => setNewCar({ ...newCar, owner_id: e.target.value })}
            >
              {owners.map(owner => (
                <MenuItem key={owner.id} value={owner.id}>
                  {owner.name}
                </MenuItem>
              ))}
            </Select>
            {errors.owner_id && <FormHelperText>{errors.owner_id}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!errors.regional_spec}>
            <InputLabel>Regional Spec</InputLabel>
            <Select
              value={newCar.regional_spec}
              onChange={(e) => setNewCar({ ...newCar, regional_spec: e.target.value })}
            >
              <MenuItem value="American">American</MenuItem>
              <MenuItem value="European">European</MenuItem>
              <MenuItem value="Asian">Asian</MenuItem>
              <MenuItem value="Middle East">Middle East</MenuItem>
              <MenuItem value="Chinese">Chinese</MenuItem>
              <MenuItem value="Japanese">Japanese</MenuItem>
              <MenuItem value="Korean">Korean</MenuItem>
              <MenuItem value="GCC">GCC</MenuItem>
            </Select>
            {errors.regional_spec && <FormHelperText>{errors.regional_spec}</FormHelperText>}
          </FormControl>
          <TextField
            label="Engine Size"
            variant="outlined"
            value={newCar.engine_size}
            onChange={(e) => setNewCar({ ...newCar, engine_size: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.engine_size}
            helperText={errors.engine_size}
          />
          <div className="editor-container">
            <Typography variant="body2">Description:</Typography>
            <ReactQuill
              value={newCar.description}
              onChange={(value) => setNewCar({ ...newCar, description: value })}
              modules={{
                toolbar: [
                  [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                  [{size: []}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image', 'video'],
                  ['clean']
                ],
              }}
              formats={[
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video'
              ]}
              className="quill-editor"
            />
          </div>
          <TextField
            label="Instagram Post URL"
            variant="outlined"
            value={newCar.instagram_post}
            onChange={(e) => setNewCar({ ...newCar, instagram_post: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.instagram_post}
            helperText={errors.instagram_post}
          />
          <TextField
            label="Warranty"
            variant="outlined"
            value={newCar.warranty}
            onChange={(e) => setNewCar({ ...newCar, warranty: e.target.value })}
            margin="normal"
            fullWidth
            error={!!errors.warranty}
            helperText={errors.warranty}
          />
          <TextField
            label="Price Per Month"
            variant="outlined"
            value={newCar.price_per_month}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                setNewCar({ ...newCar, price_per_month: value });
              }
            }}
            margin="normal"
            fullWidth
            error={!!errors.price_per_month}
            helperText={errors.price_per_month}
          />
          <div className="image-upload-section dashed-container">
            {previewImage && (
              <div>
                <Typography variant="body2">Primary Image:</Typography>
                <div className="preview-image-container">
                  <img src={previewImage} alt="Primary Preview" className="preview-image" />
                </div>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handlePrimaryImageUpload}
              style={{ display: 'none' }}
              id="upload-primary-image"
            />
            <label htmlFor="upload-primary-image" className="upload-label">
              <Button component="span" variant="contained" color="primary">
                Upload Primary Image
              </Button>
            </label>
          </div>
          

          <div className="image-upload-section dashed-container">
  <Typography variant="body2">Additional Images:</Typography>
  {previewImages.length > 0 ? (
    previewImages.map((preview, index) => (
      <div key={index} className="preview-image-container">
        <img src={preview} alt={`Preview ${index}`} className="preview-image" />
        <IconButton 
          color="secondary" 
          onClick={() => handleRemoveAdditionalImage(index)} 
          className="icon-button"
          size="large"
        >
          <DeleteIcon fontSize="large" />
        </IconButton>
      </div>
    ))
  ) : (
    <div className="upload-placeholder">No images selected</div>
  )}
  <input
    type="file"
    accept="image/*"
    multiple
    onChange={handleAdditionalImagesUpload}
    style={{ display: 'none' }}
    id="upload-car-images"
  />
  <label htmlFor="upload-car-images" className="upload-label">
    <Button component="span" variant="contained" color="primary">
      Upload Additional Images
    </Button>
  </label>
</div>



        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSaveCar}
            color="primary"
          >
            {currentCar ? 'Update Car' : 'Save Car'}
          </Button>
        </DialogActions>
      </Dialog>

      {loading && (
        <div className="loading-overlay">
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  );
};

export default CarListing;
